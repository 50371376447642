import './styles/quasar.scss'
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import '@quasar/extras/material-icons/material-icons.css'
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify
  },
}