import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { createPinia } from 'pinia'
import router from './router'
require('./fontawesome')

const pinia = createPinia()
createApp(App).use(router).use(pinia).use(Quasar, quasarUserOptions).mount('#app')
