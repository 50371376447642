<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  setup() {
    return {};
  },
};
</script>

<style lang="scss"></style>
